export const filtersAmenityTypes = [
	{
		label: "Same Day Check-In",
		selected: false,
		param: "same_day",
		type: "val",
	},
	{
		label: "Exclude Shared Spaces",
		selected: false,
		param: "non_shared",
		type: "val",
	},
	{
		label: "New Homes",
		selected: false,
		param: "new_homes",
		type: "val",
	},

	{
		label: "Pocket Friendly",
		selected: false,
		param: "pocket",
		type: "val",
	},
	{
		label: "Breakfast Included",
		selected: false,
		param: "breakfast",
		type: "val",
	},
	{
		label: "Pet friendly",
		apiValue: 29,
		selected: false,
		type: "notVal",
	},
	{
		label: "Pool villa",
		apiValue: 28,
		selected: false,
		type: "notVal",
	},
	{
		label: "Veg only",
		apiValue: 16,
		selected: false,
		type: "notVal",
	},
	// {
	// 	label: "Luxury Apartments",
	// 	selected: false,
	// 	param: "luxury_apartment",
	// 	type: "val",
	// },
	{
		label: "For Couples",
		selected: false,
		param: "couple",
		type: "val",
	},
	{
		label: "Show Sold Out Villas",
		selected: false,
		param: "unavail",
		type: "val",
	},

];
