import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// helpers
import parseHTML from "helpers/parseHTML";
import { axiosInstance } from "helpers/axiosInstance";

// icons
import { SvgRecentSearch } from "assets/icons/js/SvgRecentSearch";
import { ReactComponent as LocationBig } from "assets/icons/svg/location.svg";

// style
import "./style.scss";

export const HomeSearchLocation = memo(
	({
		open,
		setOpen,
		searchBar,
		setSearchInput,
		setSearchQuery,
		location,
		setLocation,
		searchResults,
		newResData,
		id,
		query,
		setCollectionLocation,
		type,
		setDateOpen,
	}) => {
		const [recent, setRecent] = useState([]);
		// const [coupon, setCoupon] = useState("");
		// const [searchParams, setSearchParams] = useSearchParams();

		const navigate = useNavigate();

		const { data } = searchBar;

		const newData = data?.data?.data;

		const popRef = useRef();

		useEffect(() => {
			function handleClickOutside(event) {
				if (popRef.current && !popRef.current.contains(event.target)) {
					setOpen(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [popRef]);

		const newArray = (newData && Object?.values(newData).slice(0, 7)) || [];

		// getting the recentSearch data
		const recentData = window.localStorage.getItem("recent") || [];
		const recentArray = recentData.length > 0 && JSON.parse(recentData);

		let results = [];

		if (newArray.length > 0) {
			// const homeObjects = newArray.filter((item) => item.type === "Home");
			// const cityObjects = newArray.filter((item) => item.type === "City");
			// const collectionObjects = newArray.filter(
			// 	(item) => item.type === "Collection",
			// );
			// const photosObjects = newArray.filter((item) => item.type === "Photos");
			// results = [
			// 	...homeObjects,
			// 	...cityObjects,
			// 	...collectionObjects,
			// 	...photosObjects,
			// ];
			results = [...newArray];
		}

		const handleClick = (search) => {
			if (type === "inspired") {
				// navigate(
				// 	`/collection/${id}-in-${search.title}?lat=${search.latitude}&lon=${search.longitude}`,
				// );
				setCollectionLocation({
					lat: search.latitude,
					lon: search.longitude,
				});
				setOpen(false);
				setSearchInput(parseHTML(search.title));
				setSearchQuery(parseHTML(search.title));
			} else {
				if (search.type === "Collection") {
					window.location.href = `/collection/${search.seo_url}`;
				}
				if (search.type === "Photos") {
					window.location.href = "/discovery?tag=" + search.title;
					return;
				}
				if (search.type === "City" || search.type === "State" || search.type === "Region") {
					setLocation({
						latitude: search.latitude,
						longitude: search.longitude,
					});
					setSearchInput(parseHTML(search.title));
					setSearchQuery(parseHTML(search.title));
					setDateOpen();
					setOpen(false);
					setRecent((prev) =>
						prev.filter((v) => v.title === search.title).length === 0
							? [
									...prev,
									{
										latitude: search.latitude,
										longitude: search.longitude,
										title: parseHTML(search.title),
									},
							  ]
							: [
									...prev.filter((v) => v.title !== search.title),
									{
										latitude: search.latitude,
										longitude: search.longitude,
										title: parseHTML(search.title),
									},
							  ],
					);
				}
				if (search.type === "Home") {
					window.open(
						`/view/${search.seo_url + "-"}${search.id}?adults=${
							query?.get("adults") || ""
						}&children=${query?.get("children") || ""}&infants=${
							query?.get("infants") || ""
						}&rooms=${query?.get("rooms") || ""}&checkin=${""}&checkout=${""}`,
						"_blank",
					);
				}
			}
		};

		// setting the recentSearch data
		useEffect(() => {
			if (recent?.length > 0) {
				window.localStorage.setItem(
					"recent",
					JSON.stringify(recent.reverse().slice(0, 5)),
				);
			}
			if (recent?.length === 0 && recentArray?.length > 0) {
				setRecent([...recentArray]);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [recent]);

		const getData = async (urlData) => {
			try {
				const { data } = await axiosInstance.get(
					`/auto/complete2.0?text=${urlData}`,
				);

				if (
					window.location.pathname &&
					window.location.pathname?.split("/")[1] === "villas"
				) {
					setLocation({
						latitude: Object.values(data.data)[0].LATITUDE,
						longitude: Object.values(data.data)[0].LONGITUDE,
					});
				} else {
					window.location.href = `/villas/villas-in-${urlData}?checkin=${""}&checkout=${""}&guests=${""}&rooms=${""}&lat=${
						Object.values(data.data)[0].LATITUDE
					}&lon=${
						Object.values(data.data)[0].LONGITUDE
					}&amenities=${""}&events=${""}`;
				}
			} catch (error) {
				new Error({ cause: error.message });
			}
		};

		useEffect(() => {
			if (
				window.location.pathname.split("/")[
					window.location.pathname.split("/").length - 1
				] !== "villas-near-me"
			) {
				if (
					window.location.pathname &&
					window.location.pathname.split("/")[1] === "an-ideal-weekend-getaway"
				) {
					const urlData = window.location.pathname
						.split("/")
						[window.location.pathname.split("/").length - 1].replaceAll(
							",",
							"%",
						)
						.split("%")[0]
						.replaceAll(",", "");
					getData(urlData);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			if (window.location.pathname === "/coupons-home") {
				navigate(
					`/villas/villas-in-${""}?checkin=${""}&checkout=${""}&guests=${""}&rooms=${""}&lat=${""}&lon=${""}&amenities=${""}&events=${""}&coupon=${
						window.location.search.split("?")[1].split("=")[1]
					}`,
				);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		useEffect(() => {
			if (
				window.location.pathname.split("/")[
					window.location.pathname.split("/").length - 1
				] !== "villas-near-me"
			) {
				if (
					window.location.pathname &&
					window.location.pathname.split("/")[1] === "villas" &&
					!query?.get("lat") &&
					!query?.get("lon")
				) {
					const urlDataArray = window.location.pathname
						.split("/")
						[window.location.pathname.split("/").length - 1].split("-");
					const urlData = urlDataArray[urlDataArray.length - 1];

					getData(urlData);
				}
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		if (open)
			return (
				<div className="location-popup-wrapper" ref={popRef} tabIndex={0}>
					<div className="suggest-left">
						{newArray?.length > 0
							? results?.map((search) => {
									return (
										<div
											className="home-suggest-wrapper"
											onClick={() => handleClick(search)}
											key={Math.random() * 1000}
										>
											{search.image_id ? (
												<div className="home-suggest-img">
													<img
														src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-24,h-24,q-40/${search?.image_id}`}
														alt="#"
													/>
												</div>
											) : (
												<LocationBig />
											)}
											<div className="home-suggest-details">
												<span>
													<h4>{parseHTML(search.title)}</h4>
													<h5>{search.type}</h5>
												</span>
												<h5>{search.subtitle}</h5>
											</div>
										</div>
									);
							  })
							: recentArray &&
							  recentArray.length > 0 &&
							  recentArray?.map((search) => {
									return (
										<div
											className="home-suggest-wrapper"
											onClick={() => {
												setLocation({
													latitude: search.latitude,
													longitude: search.longitude,
												});
												setSearchInput(parseHTML(search.title));
												setSearchQuery(parseHTML(search.title));
												setOpen(false);
												setDateOpen();
											}}
											key={Math.random() * 1000}
										>
											<SvgRecentSearch />
											<div className="home-suggest-details">
												<h4>{parseHTML(search.title)}</h4>
												<h5>{search.state}</h5>
											</div>
										</div>
									);
							  })}
					</div>
				</div>
			);
		return null;
	},
);
