import React, {
	MouseEventHandler,
	memo,
	useContext,
	useEffect,
	useState,
} from "react";
import "./style.scss";

import { ReactComponent as CalendarSvg } from "../assets/calendar.svg";
import { ReactComponent as ClockSvg } from "../assets/clock.svg";
import { ReactComponent as DollarSvg } from "../assets/dollar.svg";
import { ReactComponent as CoupleSvg } from "../assets/couple.svg";
import { SvgInfo as Info } from "assets/icons/js/SvgInfo";
import { SvgArrowDown } from "assets/icons/js/SvgArrowDown";

import { shortDateFormat } from "helpers/dateFormatter";

import { inBetweenDates, compareDates, customDates, format } from "multi-date";
import { DatePicker } from "react-responsive-calendar-picker";
import "react-responsive-calendar-picker/dist/index.css";
import Modal from "components/LoginAndRegister/Modal";
import axios from "axios";
import { getBlockedDates } from "helpers/getBloackedDates";
import minNightsData from "lib/min2NightsData";
import { BookingInterface } from "context/BookingData";
import BookingCoupon from "../Coupon";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "customHooks/useMediaQuery";

const GuestDetail = ({ data }: any) => {
	const { bookingData, updateBooking, fetchCoupons, removeCoupon }: any =
		useContext(BookingInterface);
	const totalGuest = data?.NO_OF_GUESTS + data?.NUM_CHILDREN;

	const [openCalendar, setOpenCalendar] = useState(false);
	const [openGuestData, setOpenGuestData] = useState(false);
	const [openCheckin, setOpenCheckin] = useState(false);
	const [openCoupon, setOpenCoupon] = useState(false);

	const getCouponData = async () => {
		await fetchCoupons.mutateAsync(data);
	};

	useEffect(() => {
		getCouponData();
	}, []);

	const [searchParams, setSearchParams] = useSearchParams();

	return (
		<>
			<Modal
				shouldShow={openCalendar}
				onRequestClose={() => {
					setOpenCalendar(false);
				}}
			>
				<CalendarPopUp
					listingId={data?.LISTING_ID}
					checkin={data?.FROM_DATE}
					checkout={data?.TO_DATE}
					roomsData={data?.NO_OF_ROOMS}
					updateBooking={updateBooking}
					bookingData={bookingData}
					setOpen={setOpenCalendar}
				/>
			</Modal>
			<Modal
				shouldShow={openGuestData}
				onRequestClose={() => {
					setOpenGuestData(false);
				}}
			>
				<GuestDataPopUp
					data={data}
					updateBooking={updateBooking}
					setOpen={setOpenGuestData}
				/>
			</Modal>
			<Modal
				onRequestClose={() => {
					setOpenCheckin(false);
				}}
				shouldShow={openCheckin}
			>
				<CheckinTimeSelector
					updateBooking={updateBooking}
					setOpen={setOpenCheckin}
				/>
			</Modal>
			<Modal
				shouldShow={openCoupon}
				onRequestClose={() => {
					setOpenCoupon(false);
				}}
			>
				<BookingCoupon
					data={data}
					fetchCoupons={fetchCoupons}
					closeModal={() => {
						setOpenCoupon(false);
					}}
				/>
			</Modal>
			<div className="guest-details-wrapper">
				<div className="guest-detail-box">
					<CalendarSvg />
					<div className="detail-edit-wrapper">
						<div>
							<span>{shortDateFormat(data?.FROM_DATE)}</span> ➜&nbsp;{" "}
							<span>
								{shortDateFormat(data?.TO_DATE)}{" "}
								{new Date(data?.FROM_DATE)?.getFullYear()}
							</span>
						</div>
						<p>{inBetweenDates(data?.TO_DATE, data?.FROM_DATE)} Nights</p>
					</div>
					<button
						onClick={() => {
							setOpenCalendar(true);
						}}
					>
						Edit
					</button>
				</div>
				<div className="guest-detail-box">
					<CoupleSvg />
					<div>
						<span>
							{data.TYPE_FLAG === "MR" && data?.NO_OF_ROOMS + " Room(s) ,"}
							{data.TYPE_FLAG === "EV-MR" && data?.NO_OF_ROOMS + " Room(s) ,"}
							{data.TYPE_FLAG === "MV" && data?.NO_OF_ROOMS + " Villa(s) ,"}
							{data?.NO_OF_GUESTS} adult(s)
							{data?.NUM_CHILDREN ? `, ${data?.NUM_CHILDREN} children` : ""}
							{data?.NUM_SMALL_CHILDREN
								? `, ${data?.NUM_SMALL_CHILDREN} infants `
								: ""}
							{data?.NUMBER_OF_MAIDS ? `, ${data?.NUMBER_OF_MAIDS} maid` : ""}
							{data?.NUMBER_OF_DRIVERS
								? `, ${data?.NUMBER_OF_DRIVERS} driver`
								: ""}
							{data?.NUMBER_OF_PETS ? `, ${data?.NUMBER_OF_PETS} pets` : ""}
						</span>
						<p>Total {totalGuest} guest</p>
					</div>
					<button
						onClick={() => {
							setOpenGuestData(true);
						}}
					>
						Edit
					</button>
				</div>
				{/* <div className="guest-detail-box">
					<ClockSvg />
					<div>
						<p style={{ margin: 0 }}>Check-in time</p>
						<span>-/-</span>
					</div>
					<button
						onClick={() => {
							setOpenCheckin(true);
						}}
					>
						Edit
					</button>
				</div> */}
				{data?.REWARDPOINTS_APPLICABLE &&
				fetchCoupons?.data?.data?.data?.length > 0 ? (
					<div className="guest-detail-box coupon-box">
						<DollarSvg />
						<div className="discount-cover">
							<h3
								onClick={() => {
									if (!data?.COUPON_CODE) setOpenCoupon(true);
								}}
								style={{
									opacity: data?.COUPON_CODE ? 1 : 0.5,
									cursor: !data?.COUPON_CODE ? "pointer" : "auto",
								}}
								className={data?.COUPON_CODE ? "bold-text" : ""}
							>
								{data?.COUPON_CODE || "I have a coupon code"}
							</h3>
							{data?.COUPON_CODE ? (
								<h5>
									<b>🎉</b>{" "}
									<div>
										Coupon Applied!
										<br />
										You saved ₹{data?.COUPONAMOUNT_TAX}!
									</div>
								</h5>
							) : (
								<h5>
									Save UPTO ₹{fetchCoupons?.data?.data?.data[0]?.discount}
								</h5>
							)}
						</div>
						<button
							onClick={() => {
								if (data?.COUPON_CODE) {
									updateBooking.mutate({
										COUPON_CODE: "",
									});
									searchParams.set("coupon", "");
									setSearchParams(searchParams, { replace: true });
									return;
								}
								setOpenCoupon(true);
							}}
						>
							{data?.COUPON_CODE ? "Remove" : "Select Code"}
						</button>
					</div>
				) : (
					<div className="guest-detail-box coupon-box">Loading...</div>
				)}
			</div>
		</>
	);
};

export default GuestDetail;

const CalendarPopUp = ({
	listingId,
	checkin,
	checkout,
	roomsData,
	updateBooking,
	bookingData,
	setOpen,
}: {
	listingId: string;
	checkin: string;
	checkout: string;
	roomsData: string;
	updateBooking: any;
	bookingData: any;
	setOpen: any;
}) => {
	const [dates, setDates] = useState<any>({
		checkin: new Date(checkin),
		checkout: new Date(checkout),
	});
	const [storeDates, setStoreDates] = useState([]);
	const [blockDates, setBlockDates] = useState([]);
	const [blockedTemp, setBlockedTemp] = useState([]);

	const getCalendarData = async () => {
		const checkout = customDates(format(new Date(), "YYYY-MM-DD"), 1, "year");
		let res = await axios(
			`https://ecapi2.saffronstays.com/fetch-calendar2/${listingId}?checkIn=${format(
				new Date(),
				"YYYY-MM-DD",
			)}&checkOut=${format(checkout, "YYYY-MM-DD")}`,
		);
		setStoreDates(res.data[listingId]);
	};
	useEffect(() => {
		getCalendarData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBlockedDate = async () => {
		//@ts-ignore
		setBlockDates(await getBlockedDates(listingId, roomsData, storeDates));
		if (blockedTemp.length === 0) {
			//@ts-ignore
			setBlockedTemp(await getBlockedDates(listingId, roomsData, storeDates));
		}
	};
	useEffect(() => {
		if (storeDates.length !== 0) getBlockedDate();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeDates]);

	// getBetweenDates
	const getBetweenDates = (start: any) => {
		let arr = [];
		for (let i = 1; i < 100; i++) {
			arr.push(format(customDates(start, i, "day"), "YYYY-MM-DD"));
		}
		return arr;
	};

	const [spl, setSpl] = useState(false);

	const getNextBlockDate = (d: string) => {
		let i;
		for (i = 0; i < blockedTemp.length; i++) {
			if (compareDates(blockedTemp[i], d) === -1) {
				return i;
			}
		}
		return 0;
	};

	const calculateBlockedDates = (flag = 1) => {
		let tempArr = [...blockedTemp];
		for (let i = 1; i <= 30; i++) {
			let checkIn = format(customDates(dates.checkin, i, "day"), "YYYY-MM-DD");

			if (
				// @ts-ignore
				tempArr.includes(checkIn) ||
				minNightsData(listingId)?.includes(format(dates.checkin, "YYYY-MM-DD"))
			) {
				// @ts-ignore
				let indx = tempArr.indexOf(checkIn);
				const t = tempArr.splice(indx, 1);

				if (flag === 1) {
					const nextDay = customDates(dates.checkin, 1, "day");
					if (
						minNightsData(listingId).includes(
							format(dates.checkin, "YYYY-MM-DD"),
						) &&
						tempArr.includes(
							// @ts-ignore
							format(customDates(dates.checkin, 2, "day"), "YYYY-MM-DD"),
						)
					) {
						if (
							!blockedTemp.includes(
								// @ts-ignore
								format(customDates(dates.checkin, 1, "day"), "YYYY-MM-DD"),
							)
						) {
							setSpl(true);
							// @ts-ignore
							setBlockDates([
								...tempArr.splice(0, getNextBlockDate(dates.checkin)),
								format(nextDay, "YYYY-MM-DD"),
								...getBetweenDates(
									format(customDates(dates.checkin, 2, "day"), "YYYY-MM-DD"),
								),
							]);
						} else {
							// @ts-ignore
							setBlockDates([...tempArr.slice(0, indx), ...getBetweenDates(t)]);
						}
					} else if (
						minNightsData(listingId).includes(
							format(dates.checkin, "YYYY-MM-DD"),
						) &&
						// @ts-ignore
						!tempArr.includes(format(nextDay, "YYYY-MM-DD"))
					) {
						// @ts-ignore
						if (blockedTemp.includes(format(nextDay, "YYYY-MM-DD"))) {
							// @ts-ignore
							setBlockDates([...tempArr.slice(0, indx), ...getBetweenDates(t)]);
						} else {
							setSpl(true);
							if (getNextBlockDate(dates.checkin)) {
								// @ts-ignore
								setBlockDates([
									// @ts-ignore
									...tempArr.slice(0, getNextBlockDate(dates.checkin)),
									// @ts-ignore
									format(nextDay, "YYYY-MM-DD"),
									// @ts-ignore
									...getBetweenDates(tempArr[getNextBlockDate(dates.checkin)]),
								]);
							} else {
								// @ts-ignore
								setBlockDates([
									// @ts-ignore
									...tempArr.slice(0, indx),
									// @ts-ignore
									format(nextDay, "YYYY-MM-DD"),
									// @ts-ignore	...tempArr.slice(0, indx),
									...tempArr.slice(indx, tempArr.length - 1),
								]);
							}
						}
					} else {
						// @ts-ignore
						setBlockDates([...tempArr.slice(0, indx), ...getBetweenDates(t)]);
					}
				} else {
					setSpl(false);
					setBlockDates([
						...tempArr.slice(0, indx),
						...tempArr.slice(indx, tempArr.length - 1),
					]);
				}
				break;
			}
		}
	};
	useEffect(() => {
		if (storeDates.length !== 0) {
			if (!dates.checkin) {
				getBlockedDate();
			} else if (dates.checkin && !dates.checkout) {
				calculateBlockedDates();
			} else if (dates.checkin && dates.checkout) {
				calculateBlockedDates(0);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dates]);

	const [loading, setLoading] = useState(false);

	const update = async () => {
		setLoading(true);
		try {
			await updateBooking.mutateAsync({
				FROM_DATE: format(dates.checkin, "YYYY-MM-DD"),
				TO_DATE: format(dates.checkout, "YYYY-MM-DD"),
			});
		} catch (err) {}

		setLoading(false);
		setOpen();
	};

	// useEffect(() => {
	// 	if (
	// 		format(dates.checkin, "YYYY-MM-DD") !== checkin &&
	// 		format(dates.checkout, "YYYY-MM-DD") !== checkout &&
	// 		dates.checkin &&
	// 		dates.checkout
	// 	) {
	// 		update();
	// 	}
	// }, [dates]);

	const width = useMediaQuery();

	return (
		<div className="calendar-wrapper booking-popup">
			<div className="calendar-top">Check in - Check out</div>
			{width > 1000 ? (
				<>
					<DatePicker
						dates={dates}
						setDates={setDates}
						open={true}
						setOpen={() => {}}
						spl={spl}
						blocked={blockDates}
						// noMonth={2}
						// mobile={false}
						style={{
							position: "relative",
							boxShadow: "none",
							border: "none",
							pointerEvents: loading && "none",
						}}
						detail={storeDates}
					/>
				</>
			) : (
				<DatePicker
					dates={dates}
					setDates={setDates}
					open={true}
					setOpen={() => {}}
					spl={spl}
					blocked={blockDates}
					noMonth={2}
					mobile={true}
					style={{
						position: "relative",
						boxShadow: "none",
						border: "none",
						pointerEvents: loading && "none",
					}}
					detail={storeDates}
				/>
			)}
			{/* ) : (
				<DatePicker
					dates={dates}
					setDates={setDates}
					open={true}
					setOpen={() => {}}
					spl={spl}
					blocked={blockDates}
					noMonth={2}
					mobile={true}
					style={{
						position: "relative",
						boxShadow: "none",
						border: "none",
						pointerEvents: loading && "none",
					}}
					detail={storeDates}
				/>
			)} */}
			<div className="btn-wrapper">
				<button
					onClick={() => {
						setDates({
							checkin: "",
							checkout: "",
						});
					}}
				>
					Clear All
				</button>
				<button
					className={`blue-btn ${loading ? "button__loader" : ""}`}
					onClick={update}
				>
					Update
				</button>
			</div>
		</div>
	);
};

const GuestDataPopUp = ({ data, updateBooking, setOpen }: any) => {
	const [adult, setAdult] = useState(0);
	const [children, setChildren] = useState(0);
	const [infrants, setInfrants] = useState(0);
	const [driver, setDriver] = useState(0);
	const [maids, setMaids] = useState(0);
	const [pets, setPets] = useState(0);

	const [max, setMax] = useState(0);
	const [roomsData, setRoomsData] = useState(0);
	const [loading, setLoading] = useState(false);
	const [minRooms, setMinRooms] = useState(0);

	useEffect(() => {
		setAdult(data.NO_OF_GUESTS);
		setChildren(data.NUM_CHILDREN);
		setInfrants(data.NUM_SMALL_CHILDREN);
		setDriver(data.NUMBER_OF_DRIVERS);
		setMaids(data.NUMBER_OF_MAIDS);
		setPets(data.NUMBER_OF_PETS);
		setRoomsData(data.NO_OF_ROOMS);
		setMax(data.MAX_CAPACITY);
		setMinRooms(data.MIN_ROOMS);
	}, [data]);

	useEffect(() => {
		setMax((data.MAX_CAPACITY / data.NUM_SIMILAR_ROOMS) * roomsData);

		if (
			adult + children >
			(data.MAX_CAPACITY / data.NUM_SIMILAR_ROOMS) * roomsData
		) {
			setAdult((data.MAX_CAPACITY / data.NUM_SIMILAR_ROOMS) * roomsData);
			setChildren(0);
		}
	}, [roomsData]);

	const update = async () => {
		setLoading(true);
		try {
			let body = {
				NUM_ADULTS: adult,
				NUM_CHILD: children,
				NUM_INFANTS: infrants,
				NUM_MAIDS: maids,
				NUM_DRIVERS: driver,
				NUM_PETS: pets,
			};
			if (
				data.TYPE_FLAG === "MR" ||
				data.TYPE_FLAG === "MV" ||
				data.TYPE_FLAG === "EV-MR" ||
				data.TYPE_FLAG === "SR"
			) {
				//@ts-ignore
				body.NUM_ROOMS = roomsData;
			}
			await updateBooking.mutateAsync(body);
		} catch (err) {}
		setLoading(false);
		setOpen(false);
	};

	const dataObj = [
		{
			count: adult,
			onIncre: () => {
				setAdult(adult + 1);
			},
			onDecre: () => {
				if (adult) setAdult(adult - 1);
			},
			shortTitle: "Age 12+",
			title: "Adults",
			disable: max <= adult + children,
			min: 1,
		},
		{
			count: children,
			onIncre: () => {
				setChildren(children + 1);
			},
			onDecre: () => {
				if (children) setChildren(children - 1);
			},
			shortTitle: "Age 6-11",
			title: "Children",
			disable: max <= adult + children,
		},
		{
			count: infrants,
			onIncre: () => {
				setInfrants(infrants + 1);
			},
			onDecre: () => {
				if (infrants) setInfrants(infrants - 1);
			},
			shortTitle: "Age 0-5",
			title: "Infants",
		},
	];
	if (data.L_DRIVERS_CHARGES) {
		dataObj.push({
			count: driver,
			onIncre: () => {
				setDriver(driver + 1);
			},
			onDecre: () => {
				if (driver) setDriver(driver - 1);
			},
			title: "Drivers",

			shortTitle: "",
		});
	}
	if (data.L_MAID_CHARGES) {
		dataObj.push({
			count: maids,
			onIncre: () => {
				setMaids(maids + 1);
			},
			onDecre: () => {
				if (maids) setMaids(maids - 1);
			},
			title: "Maids",
			shortTitle: "",
		});
	}
	if (data.L_PET_CHARGES) {
		dataObj.push({
			count: pets,
			onIncre: () => {
				if (pets < 2) setPets(pets + 1);
			},
			onDecre: () => {
				if (pets) setPets(pets - 1);
			},
			title: "Pets",
			shortTitle: "",
			disable: pets === 2,
		});
	}
	if (data.TYPE_FLAG === "EV-MR") {
		dataObj.unshift({
			count: roomsData,
			onIncre: () => {
				setRoomsData(roomsData + 1);
			},
			onDecre: () => {
				if (roomsData) setRoomsData(roomsData - 1);
			},
			shortTitle: "",
			title: "Room(s)",
			disable: roomsData === data?.NUM_SIMILAR_ROOMS,
			min: minRooms,
		});
	}
	if (data.TYPE_FLAG === "MR") {
		dataObj.unshift({
			count: roomsData,
			onIncre: () => {
				setRoomsData(roomsData + 1);
			},
			onDecre: () => {
				if (roomsData) setRoomsData(roomsData - 1);
			},
			shortTitle: "",
			title: "Room(s)",
			disable: roomsData === data?.NUM_SIMILAR_ROOMS,
			min: 1,
		});
	}
	if (data.TYPE_FLAG === "MV") {
		dataObj.unshift({
			count: roomsData,
			onIncre: () => {
				setRoomsData(roomsData + 1);
			},
			onDecre: () => {
				if (roomsData) setRoomsData(roomsData - 1);
			},
			shortTitle: "",
			title: "Villas",
			disable: roomsData === data?.NUM_SIMILAR_ROOMS,
			min: 1,
		});
	}
	return (
		<div className="guest-data-wrapper booking-popup">
			<h3>Guests</h3>
			<p>
				This home has a maximum of {max} guests, not including infants. If
				you’re bringing pets, please let us know.
			</p>
			<div className="guest-selector-wrapper">
				{dataObj.map((ele) => {
					return (
						<GuestSelector
							shortTitle={ele?.shortTitle}
							title={ele.title}
							count={ele.count}
							onIncre={ele.onIncre}
							onDecre={ele.onDecre}
							disable={ele?.disable || false}
							min={ele.min || 0}
						/>
					);
				})}
			</div>
			<div className="btn-wrapper">
				<button
					onClick={() => {
						setAdult(data.NO_OF_GUESTS);
						setChildren(data.NUM_CHILDREN);
						setInfrants(data.NUM_SMALL_CHILDREN);
						setDriver(data.NUMBER_OF_DRIVERS);
						setMaids(data.NUMBER_OF_MAIDS);
						setPets(data.NUMBER_OF_PETS);
						setRoomsData(data.NO_OF_ROOMS);
					}}
				>
					Clear All
				</button>
				<button
					className={`blue-btn ${loading ? "button__loader" : ""}`}
					onClick={update}
				>
					Update
				</button>
			</div>
		</div>
	);
};

const GuestSelector = ({
	count,
	onIncre,
	onDecre,
	shortTitle,
	title,
	disable = false,
	min,
}: {
	count: number;
	onIncre: MouseEventHandler<HTMLButtonElement>;
	onDecre: MouseEventHandler<HTMLButtonElement>;
	shortTitle?: string | undefined;
	title: string;
	disable: boolean;
	min: number;
}) => {
	return (
		<div className="guest-selector">
			<div className="guest-title">
				<b>{title}</b>
				<span>{shortTitle}</span>
			</div>
			<div className="guest-count">
				<button onClick={onDecre} disabled={count === min}>
					-
				</button>
				<span>{count}</span>
				<button onClick={onIncre} disabled={disable}>
					+
				</button>
			</div>
		</div>
	);
};

const CheckinTimeSelector = ({ updateBooking, setOpen }: any) => {
	const checkinTime = [
		"Not Sure",
		"01:00 PM - 03:00 PM",
		"03:00 PM - 05:00 PM",
		"05:00 PM - 07:00 PM",
		"07:00 PM - 09:00 PM",
		"9:00 PM - 12:00 AM",
	];

	const [loading, setLoading] = useState(false);

	const [checkinDropdown, setCheckinDropdown] = useState(false);
	const [checkinTimeVal, setCheckinTimeVal] = useState("");

	const update = async () => {
		setLoading(true);
		try {
			await updateBooking.mutateAsync({
				TIMEOF_CHECKIN: checkinTimeVal,
			});
		} catch (err) {}
		setLoading(false);
		setOpen(false);
	};

	return (
		<div className="checkin-time-wrapper booking-popup">
			<h3>Guests</h3>
			<p style={{ marginBottom: 20 }}>
				This home has a maximum of 8 guests, not including <br />
				infants. If you’re bringing pets, please let us know.
			</p>
			<div className="checkin__time__container">
				<div
					className="checkin-time"
					onClick={() => setCheckinDropdown((prev) => !prev)}
				>
					<span className="time">
						<span>
							<ClockSvg />
							<p>{checkinTimeVal}</p>
						</span>
						<SvgArrowDown
							style={{
								rotate: checkinDropdown ? "180deg" : "",
							}}
						/>
					</span>
					{checkinDropdown && (
						<div className="checkin-dropdown">
							{checkinTime.map((v, i) => {
								return (
									<span
										className="options"
										key={i}
										onClick={() => setCheckinTimeVal(v)}
									>
										{v}
									</span>
								);
							})}
						</div>
					)}
				</div>
				{(checkinTimeVal === "8:00 AM - 10:00 AM" ||
					checkinTimeVal === "10:00 AM - 12:00 PM") && (
					<p className="info__text">
						<span className="right-info">
							<Info />
						</span>
						Early check-in would be enabled one day before your checkin date
						based on availability at an extra fee.
					</p>
				)}
			</div>
			<div className="btn-wrapper">
				<button onClick={() => {}}>Clear All</button>
				<button
					className={`blue-btn ${loading ? "button__loader" : ""}`}
					onClick={update}
				>
					Update
				</button>
			</div>
		</div>
	);
};
