import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import clevertap from "clevertap-web-sdk";

// context
import { UserContext } from "store";

// icons
import { SvgNewHomeLogo as NewHomeLogo } from "assets/icons/js/SvgNewHomeLogo";
import { ReactComponent as Cross } from "./assets/cross.svg";
import { ReactComponent as Dropdown } from "assets/icons/downarrow.svg";

// customHooks
import { useUser } from "customHooks/useUser";
import { useMediaQuery } from "customHooks/useMediaQuery";
import { ReactComponent as PhoneSvg } from "assets/phone.svg";

// scss
import "./style.scss";
import { SvgSearch } from "assets/icons/js/SvgSearch";
import { ReactComponent as ExploreIcon } from "./assets/explore.svg";
import { ReactComponent as LocationIcon } from "./assets/location.svg";
import { ReactComponent as BookingIcon } from "./assets/booking.svg";
import { ReactComponent as WishListIcon } from "./assets/wishlist.svg";
import { ReactComponent as ConnectIcon } from "./assets/connect.svg";
import { ReactComponent as TierLogo } from "./assets/tierLogo.svg";
import { ReactComponent as HomeIcon } from "./assets/home.svg";
import { ReactComponent as LoginRegister } from "./assets/loginRegister.svg";
import { ReactComponent as ContactUs } from "./assets/contactUs.svg";
import axios from "axios";
import {
	amenities,
	categories,
	collections,
	destinations,
} from "./constant/mobNavData";

export const SideBar = ({ setShouldShowModal, isMenuOpen, setIsMenuOpen }) => {
	const { state } = useContext(UserContext);
	const { currentUser } = state;
	const [explore, setExplore] = useState(false);
	const [homeOwners, setHomeOwners] = useState(false);

	const navigate = useNavigate();

	const width = useMediaQuery();

	const user = useUser();

	const isMobile = width <= "500" ? true : false;

	const handleLogin = () => {
		if (isMobile) {
			// true for mobile device
			navigate("/login");
		} else {
			// false for not mobile device
			setShouldShowModal(true);
			setIsMenuOpen(false);
		}
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("uuid");
		window.location.reload();
		navigate("/");
	};

	const redirectToHostConnect = () => {
		const token = localStorage.getItem("token");
		const isHomeowner = currentUser.isHomeowner;

		if (token && isHomeowner) {
			window.open(
				`http://host.saffronstays.com/account/dashboard?jwt=${token}`,
				"_blank",
			);
		} else {
			window.open(`http://host.saffronstays.com/login`, "_blank");
		}
	};

	const [coll, setColl] = useState([]);
	const [nearby, setNearby] = useState([]);
	const getCollection = async () => {
		const res = await axios("https://go.saffronstays.com/api/home-collections");
		if (res.data.success) {
			setColl(res.data.data);
		}
	};

	const getNearby = async () => {
		const res = await axios(
			"https://ecapi.saffronstays.com/get-nearest-locations",
		);
		if (res.data.success) {
			setNearby(res.data.data);
		}
	};

	useEffect(() => {
		getNearby();
		getCollection();
	}, []);

	return (
		<div className="sidebar">
			<div className="sidebar__logo">
				{/* <div></div> */}
				<div
					onClick={() => {
						window.location.href = "/";
					}}
					aria-label="homelogo"
					className="cursor-pointer"
				>
					<NewHomeLogo height={width <= "320" ? 36 : 40} />
				</div>
				<div onClick={() => setIsMenuOpen(!isMenuOpen)}>
					<Cross />
				</div>
			</div>
			<nav className="sidebar__links">
				<div
					className="nav__middle__phone"
					onClick={() => {
						window.location.href = "/mobile-search";
					}}
				>
					<input type="text" placeholder="Search for place, home..." />
					<SvgSearch color="#999999" />
				</div>
				{/* {!user && (
					<button onClick={handleLogin} className="login-btn">
						Log in/Register
					</button>
				)} */}
				{currentUser?.fullName?.length > 0 && (
					<div

						className="nav-user-details-wrapper"
					>
						<div className="nav-user-details-content">
							<div onClick={() => {
							window.location.href = "/dashboard";
						}} className="nav-user-details-top">
								<div className="nav-user-details-top-left">
									<img
										src={`https://api.dicebear.com/9.x/initials/svg?seed=${currentUser?.fullName}`}
										alt=""
									/>
								</div>
								<div className="nav-user-details-top-right">
									<p>Profile</p>
									<h3>{currentUser?.fullName}</h3>
								</div>
							</div>
							<div className="nav-user-details-bottom">
								<p>My Reward Points</p>
								<div
									style={{
										background:
											currentUser?.rewardTier === "BLUE"
												? "#5881DB"
												: currentUser?.rewardTier === "BLACK"
												? "#333"
												: "#AE8B54",
									}}
									className="nav-user-details-bottom-tier"
								>
									<p>
										<TierLogo /> {currentUser?.rewardPoint}
									</p>
									<p>{currentUser?.rewardTier} TIER</p>
								</div>
							</div>
						</div>
					</div>
				)}
				{/* <CollapseWrapper
					title={"Explore Villa Collections"}
					children={
						<div className="collection-wrapper">
							{coll.map((ele) => {
								return (
									<div
										className="col-card"
										onClick={() => {
											window.location.href = "/collection/" + ele.SLUG;
										}}
									>
										<img
											src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-200,h-200,q-80/image/upload/${ele.HOME_COVER_IMAGE}.webp`}
											alt=""
										/>
										<span className="col-title">{ele.TITLE}</span>
									</div>
								);
							})}
						</div>
					}
					icon={<ExploreIcon />}
				/>
				<CollapseWrapper
					title={"Locations"}
					icon={<LocationIcon />}
					children={
						<div className="collection-wrapper  location">
							{nearby.map((ele) => {
								return (
									<>
										<div
											className="col-card"
											onClick={() => {
												window.location.href =
													"/villas/villas-in-" + ele.title.toLowerCase();
											}}
										>
											<img
												src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-200,h-200,q-80/image/upload/${ele.icon}`}
												alt=""
											/>
											<span className="col-title">{ele.title}</span>
										</div>
										<div
											className="col-card"
											onClick={() => {
												window.location.href =
													"/villas/villas-in-" + ele.title.toLowerCase();
											}}
										>
											<img
												src={`https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:w-200,h-200,q-80/image/upload/${ele.next.icon}`}
												alt=""
											/>
											<span className="col-title">{ele.next.title}</span>
										</div>
									</>
								);
							})}
						</div>
					}
				/> */}
				<div
					style={{
						width: "100%",
						borderBottom: "2px solid #FBA919",
					}}
					className="nav-mob-location"
				>
					<CollapseWrapper title={"Destinations"}>
						<div className="nav-destination-wrapper">
							{destinations?.map((ele, index) => {
								return (
									<div className="nav-destinations-header">
										<p>{ele?.region}</p>
										<div className="nav-destinations-details">
											{ele?.places?.map((elem, idx) => {
												return (
													<p
														style={{
															textTransform:
																elem === "Alibaug & Konkan" ||
																elem === "Western Ghats" ||
																elem === "Karjat Region" ||
																elem === "Palghar Region" ||
																elem === "Nashik Region"
																	? "uppercase"
																	: "none",
															fontWeight:
																elem === "Alibaug & Konkan" ||
																elem === "Western Ghats" ||
																elem === "Karjat Region" ||
																elem === "Palghar Region" ||
																elem === "Nashik Region"
																	? "bold"
																	: "normal",
														}}
														key={idx}
														onClick={() => {
															if (
																elem !== "Alibaug & Konkan" &&
																elem !== "Western Ghats" &&
																elem !== "Karjat Region" &&
																elem !== "Palghar Region" &&
																elem !== "Nashik Region"
															) {
																window.location.href = `/villas/villas-in-${elem}?checkin=&checkout=&guests=1&adults=0&children=0&infants=0&rooms=1&pets=0&amenities=&events=&pocket=&same_day=&club=&signature=&breakfast=&radius=1&more=`;
															}
														}}
													>
														{elem}
													</p>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</CollapseWrapper>
					<CollapseWrapper title={"Collections"}>
						<div className="nav-collection-wrapper">
							{collections?.map((ele, index) => {
								return (
									<div className="nav-collections-header">
										<p>
											<span>
												<img src={ele?.icon} alt="" />
											</span>
											{ele?.category}
										</p>
										<div className="nav-collections-details">
											{ele?.items?.map((elem, idx) => {
												return (
													<p
														onClick={() => {
															window.location.href = `/${ele?.slugs[idx]}`;
														}}
													>
														{elem}
													</p>
												);
											})}
										</div>
									</div>
								);
							})}
						</div>
					</CollapseWrapper>
					{/* <CollapseWrapper title={"Amenities"}>
						<div className="nav-ammenities-wrapper">
							{amenities?.map((ele, index) => {
								return (
									<div className="nav-ammenities-header">
										<p
											onClick={() => {
												window.location.href = ele?.link;
											}}
										>
											{ele?.title}
										</p>
									</div>
								);
							})}
						</div>
					</CollapseWrapper> */}
					<CollapseWrapper title={"Categories"}>
						<div className="nav-categories-wrapper">
							{categories?.map((ele, index) => {
								return (
									<div
										onClick={() => {
											window.location.href = ele?.link;
										}}
										className="nav-categories-header"
									>
										<img src={ele?.icon} alt="" />
										<div className="nav-categories-header-text">
											<h2>{ele?.title}</h2>
											<p>{ele?.description}</p>
										</div>
									</div>
								);
							})}
						</div>
					</CollapseWrapper>
				</div>
				{currentUser?.fullName?.length > 0 ? (
					<>
						<div
							className="side-links"
							onClick={() => {
								clevertap.event.push("mobilemenu_mybookings", {
									timestamp: new Date(),
								});
								window.location.href = "/my-booking";
							}}
						>
							My Bookings <BookingIcon />
						</div>
						<div
							className="side-links"
							onClick={() => {
								window.location.href = "/collection";
							}}
						>
							Wishlist <WishListIcon />
						</div>
						<div
							className="side-links"
							onClick={() => {
								window.location.href = "/contact-us";
							}}
						>
							Contact us <ContactUs />
						</div>
					</>
				) : (
					<>
						<div className="side-links" onClick={handleLogin}>
							Login <LoginRegister />
						</div>
						<div
							className="side-links"
							onClick={() => {
								window.location.href = "https://partner.saffronstays.com/";
							}}
						>
							List your home <HomeIcon />
						</div>

						<div
							className="side-links"
							onClick={() => {
								window.location.href = "/contact-us";
							}}
						>
							Contact us <ContactUs />
						</div>
					</>
				)}

				{/* <a
					href="tel:08069160000"
					style={{
						textDecoration: "none",
						color: "black",
					}}
				>
					<div
						className="sidebar__link-text"
						style={{
							display: "flex",
							alignItems: "center",
							gap: "10px",
						}}
					>
						<PhoneSvg
							style={{
								filter: "brightness(0)",
							}}
						/>
						08069160000
					</div>
				</a> */}
				{/* <div
					style={{
						marginTop: "20px",
					}}
					className="side-links"
					onClick={redirectToHostConnect}
				>
					List your home
				</div> */}
				{/* <div
					className="side-links"
					onClick={() => {
						window.location.href = "https://www.saffronstays.com/travelpartner";
					}}
					style={{
						border: 0,
					}}
				>
					For Travel Agents
				</div> */}
				{/* <div
					className="side-links"
					onClick={() => {
						window.location.href = "/travelpartner";
					}}
					style={{
						border: 0,
					}}
				>
					For Travel Partners
				</div> */}
				{currentUser?.fullName?.length > 0 && (
					<div className="logout" onClick={handleLogout}>
						Logout
					</div>
				)}
			</nav>
		</div>
	);
};

const CollapseWrapper = ({ title, children, icon }) => {
	const [open, setOpen] = useState(false);
	return (
		<div className="collapse-wrapper">
			<div
				className="collapse-header"
				onClick={() => {
					setOpen(!open);
				}}
			>
				<span>
					{icon} {title}
				</span>
				<Dropdown
					style={{
						transition: "0.3s linear",
						transform: open && "rotate(180deg)",
					}}
				/>
			</div>
			<div className={`collapse-body ${open ? "open" : "close"}`}>
				{children}
			</div>
		</div>
	);
};
