import { useSearchParams } from "react-router-dom";
import clevertap from "clevertap-web-sdk";
import { useContext } from "react";

// components
import CustomCheckbox from "components/Search/CustomCheckbox";
import { Tooltip } from "components/Tooltip";

// context
import { SearchPageDataInterface } from "context/SearchPageData";

// style
import "./style.scss";
import { useMediaQuery } from "customHooks/useMediaQuery";

const NewCategoryAmenitiesFilters = ({ amenityTypes, toggleFilters }) => {
	const { amenitiesFilter, setAmenitiesFilter } = useContext(
		SearchPageDataInterface,
	);
	const [searchParams, setSearchParams] = useSearchParams();
	const width = useMediaQuery();
	const isLaptopOrSmaller = width < 1080 ? true : false;

	return (
		<div className="filter__category__wrapper">
			{amenityTypes.map((filter) => {
				if (filter.type === "val") {
					return (
						<div className="filter-item" key={Math.random() * 100}>
							<p
								style={{
									fontWeight:
										(searchParams.get("luxury_apartment") === "true" &&
											filter.param === "luxury_apartment") ||
										(searchParams.get("same_day") === "true" &&
											filter.param === "same_day") ||
										(searchParams.get("new_homes") === "true" &&
											filter.param === "new_homes") ||
										(searchParams.get("non_shared") === "true" &&
											filter.param === "non_shared") ||
										(searchParams.get("pocket") === "true" &&
											filter.param === "pocket") ||
										(searchParams.get("breakfast") === "true" &&
											filter.param === "breakfast") ||
										(searchParams.get("couple") === "true" &&
											filter.param === "couple") ||
										(searchParams.get("unavail") === "true" &&
											filter.param === "unavail")
											? "600"
											: "400",
								}}
							>
								{filter.label}
								{filter.param === "same_day" && !isLaptopOrSmaller ? (
									<Tooltip
										text={"Check-In allowed till 4 PM today"}
										background={"#666666"}
									/>
								) : null}
							</p>
							<input
								id="filter"
								type="checkbox"
								checked={
									searchParams.get(filter.param) === "" ||
									searchParams.get(filter.param) === "false" ||
									searchParams.get(filter.param) === null
										? false
										: true
								}
								value={
									searchParams.get(filter.param) === "false" ? false : true
								}
								onChange={() => {
									window.scrollTo({ top: 110, left: 0, behavior: "smooth" });
									if (
										searchParams.get(filter.param) === "" ||
										searchParams.get(filter.param) === null ||
										searchParams.get(filter.param) === "false"
									) {
										searchParams.set(filter.param, "true");
										setSearchParams(searchParams, { replace: true });
									} else if (
										searchParams.get(filter.param) !== null &&
										searchParams.get(filter.param) === "true"
									) {
										searchParams.set(filter.param, "false");
										setSearchParams(searchParams, { replace: true });
									}
									clevertap.event.push("search_filter", {
										type_of_filter: `${filter.label}`,
										timestamp: new Date(),
										collectionImage:
											filter.label === 29
												? "hhttps://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:q-35/image/upload/ss3/Pet-Friendly_Saee_at_Masaya"
												: filter.label === 16
												? "https://ik.imagekit.io/5tgxhsqev/saffronstays-media/tr:q-30/image/uploadss3/Veg_Only_Gardenia"
												: "",
										collection:
											filter.label === 29
												? "https://saffronstays.com/collection/veg-only-homes"
												: filter.label === 16
												? "https://saffronstays.com/collection/pet-friendly-villas"
												: "",
										url: window.location.pathname + window.location.search,
										search_location: window.location.pathname
											?.split("/")[2]
											?.split("-")[2],
									});
								}}
							/>
						</div>
					);
				} else {
					return (
						<div className="filter-item" key={Math.random() * 100}>
							<p
								style={{
									fontWeight: amenitiesFilter?.includes(filter.apiValue)
										? "600"
										: "400",
								}}
							>
								{filter.label}
							</p>
							<CustomCheckbox
								filter={filter.apiValue}
								filterName={filter.label}
								setChanged={setAmenitiesFilter}
								type="amenities"
							/>
						</div>
					);
				}
			})}
		</div>
	);
};

export default NewCategoryAmenitiesFilters;
